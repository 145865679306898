import { Injectable } from '@angular/core';
import { ProjectUser, RoleAssignment, User } from '@eeule/eeule-shared';
import { getAuth } from 'firebase/auth';
import {
  collection,
  CollectionReference,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  getDocs,
  onSnapshot,
  Query,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  setDoc,
  Unsubscribe,
  updateDoc,
  where,
} from 'firebase/firestore';
import { BehaviorSubject, from, map, Observable, Subscription, switchMap, take, throwError } from 'rxjs';
import { ActivityTrackerService } from './activityTracker/activity-tracker.service';
import { FirebaseService } from './firebase.service';
import { PermissionService } from './permission.service';

/**
 * Only for display purposes used in User List
 *
 * @export
 * @interface ProjectUserDisplay
 * @extends {ProjectUser}
 */
export interface ProjectUserDisplay extends ProjectUser {
  firstName: string;
  lastName: string;
  email: string;
  name?: string;
  roleAssignment?: RoleAssignment;
  company?: string;
  phone?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  /**
   * The Eule User with AuthUser ID (not the projectUser with projectUserID)
   *
   * @type {(BehaviorSubject<User | null>)}
   * @memberOf UserService
   */
  public euleUser$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  private _unsub: Unsubscribe | null = null;

  private _livePermissionSubscription: Subscription | null = null;
  /**
   * Used to compare incoming User-Updates with current User-Data
   *
   * @private
   * @type {(User | null)}
   * @memberOf UserService
   */
  private _lastUserState: User | null = null;

  public constructor(
    private _firebaseService: FirebaseService,
    private _permissionService: PermissionService,
    private activityTracker: ActivityTrackerService
  ) {}

  public init() {
    const authUserId = getAuth().currentUser?.uid;

    if (!authUserId) {
      throw new Error('No AuthUser');
    }

    this.activityTracker.init(authUserId);

    this._livePermissionSubscription = this._permissionService.getLiveUserPermissions(authUserId).subscribe(permissions => {
      const globalRights: string[] = permissions?.rights || [];
      this._permissionService.setCurrentUserRights(globalRights);
    });

    this._unsub = onSnapshot(doc(this._firebaseService.firestore, `users/`, authUserId || '_'), (userDoc: DocumentSnapshot): void => {
      this._ignoreTimeStampFields(userDoc);
    });
  }

  cleanUp() {
    if (this._livePermissionSubscription) {
      this._livePermissionSubscription.unsubscribe();
    }

    if (this._unsub) {
      this._unsub();
    }
    this.euleUser$.next(null);
  }

  public getUser(userId: string): Observable<User> {
    const docRef = doc(this._firebaseService.firestore, `users/`, userId);
    return from(getDoc(docRef)).pipe(map((userSnap: DocumentSnapshot<DocumentData, DocumentData>) => userSnap.data() as User));
  }

  public setUser(user: User) {
    const docRef = doc(this._firebaseService.firestore, `users/`, user.id);
    return from(setDoc(docRef, user));
  }

  public updateUser(userId: string, data: Partial<User>) {
    const docRef = doc(this._firebaseService.firestore, `users/`, userId);
    return from(updateDoc(docRef, data));
  }

  public getUserName(userId: string) {
    return this.getUser(userId).pipe(
      map((user: User) => {
        return `${user.firstName} ${user.lastName}`;
      }),
      take(1)
    );
  }

  public getAuthUsersByIds(authUsersIds: string[]): Observable<User[]> {
    const usersColRef: CollectionReference<DocumentData, DocumentData> = collection(this._firebaseService.firestore, `users`);
    const _query: Query<DocumentData, DocumentData> = query(usersColRef, where('id', 'in', authUsersIds));

    return from(getDocs(_query)).pipe(
      map((usersSnap: QuerySnapshot<DocumentData, DocumentData>) =>
        usersSnap.docs.map((user: QueryDocumentSnapshot<DocumentData, DocumentData>) => user.data() as User)
      )
    );
  }

  public mapAuthUsersDataToProjectUsers(users: ProjectUser[]): Observable<ProjectUserDisplay[]> {
    const authUserIds: string[] = users.filter(user => user.authUserId).map((user: ProjectUser) => user.authUserId!);
    return this.getAuthUsersByIds(authUserIds).pipe(
      map((authUsers: User[]) =>
        authUsers.map((euleUser: User) => {
          const mappedProjectUserDisplay: ProjectUser = users.find((user: ProjectUser) => user.authUserId === euleUser.id)!;
          return {
            ...mappedProjectUserDisplay,
            title: euleUser.title,
            firstName: euleUser.firstName,
            lastName: euleUser.lastName,
            email: euleUser.email,
            company: euleUser.company,
            phone: euleUser.phone,
          } as ProjectUserDisplay;
        })
      )
    );
  }

  public addProjectToUser(projectId: string, userId: string) {
    const userDocRef = doc(this._firebaseService.firestore, `users/${userId}`);
    return from(
      getDoc(userDocRef).then((userSnap: DocumentSnapshot<DocumentData, DocumentData>) => {
        const user: User | undefined = userSnap.data() as User;
        const _oldProjectIds = user.projectIds || [];
        user.projectIds = [..._oldProjectIds, projectId];

        // Refer new Project in Creating Users ProjectIds

        return setDoc(userDocRef, user)
          .then(() => {})
          .catch((error: Error) => {
            console.error('ERROR in addProjectToUser()', error);
          });
      })
    );
  }

  public deleteProjectFromUser(projectId: string, authUserId: string) {
    if (!projectId) return throwError(() => 'No ProjectID');
    if (!authUserId) return throwError(() => 'No UserID');

    return this.getUser(authUserId).pipe(
      switchMap((user: User) => {
        const indexOfProjectId = user.projectIds!.indexOf(projectId);
        user.projectIds!.splice(indexOfProjectId, 1);
        return this.updateUser(user.id, { projectIds: user.projectIds });
      })
    );
  }

  /**
   * Only calls next() on user if there are "real" changes.
   * Updates on the Fields `lastActive` and `updateTime` are considered no real updates.
   *
   * @private
   * @param {DocumentSnapshot<DocumentData, DocumentData>} userDoc
   * @returns
   *
   * @memberOf UserService
   */
  private _ignoreTimeStampFields(userDoc: DocumentSnapshot<DocumentData, DocumentData>) {
    const _user: User = userDoc.data() as User;

    // Falls es das erste Mal ist, direkt setzen
    if (!this._lastUserState) {
      this._lastUserState = _user;
      this.euleUser$.next(_user);
      return;
    }
    // Prüfe, ob sich nur die Felder `lastActive` und `updateTime` geändert haben. Keine Emmitierung falls andere Werte unverändert.
    const { lastActive: oldActivity, updateTime: oldUpdatedTime, ...oldUser } = this._lastUserState;
    const { lastActive: newActivity, updateTime: newUpdatedTime, ...newUser } = _user;

    if (stringifySorted(oldUser) !== stringifySorted(newUser)) {
      // Speichere den aktuellen User-Zustand für den nächsten Vergleich
      this._lastUserState = _user;
      this.euleUser$.next(_user);
    }
  }
}

function stringifySorted(obj: any): string {
  return JSON.stringify(obj, Object.keys(obj).sort());
}
